import intercom from '../../utils/intercom.ts';

function shutdownIntercom() {
  const intercomObserver = new MutationObserver((mutationEvents) => {
    mutationEvents.forEach((mutation) => {
      const newNodes = Array.from(mutation.addedNodes);
      newNodes.forEach((node) => {
        if (node.matches('#intercom-frame')) {
          intercom.execute('shutdown');
          intercomObserver.disconnect();
        }
      });
    });
  });

  intercomObserver.observe(document.body, { childList: true });
}

if (window.location.pathname === '/logged-out') {
  shutdownIntercom();
}
