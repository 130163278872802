import * as Sentry from '@sentry/browser';

if (process.env.ENV === 'production' && process.env.SOURCE_VERSION) {
  Sentry.init({
    dsn: 'https://937753e6faec49f1a019e5e9aadc5283@o79218.ingest.sentry.io/171869',
    environment: 'production',
    release: process.env.SOURCE_VERSION,
    ignoreErrors: [/window\.UET is not a constructor/i],
  });

  const user = window.currentUser;

  if (user && user.email && user.id) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: user.email,
        id: user.id,
      });
    });
  }

  window.addEventListener('unhandledrejection', (e) => {
    Sentry.captureException(e.reason);
  });
}

export default Sentry;
